
import {
  Component,
  Vue
} from 'vue-property-decorator'
import SettingService from '../../../../services/setting-service'
import DateHelper from '@/mixins/date-mixins'
import {
  JobModule
} from '../../../../store/JobModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'JobSettings'
  })
export default class JobSettings extends Vue {
    protected isRemark = false
    protected statuses = [{
      value: 'commenced',
      text: 'Commence'
    },
    {
      value: 'archived',
      text: 'Archive'
    },
    {
      value: 'canceled',
      text: 'Cancel'
    },
    {
      value: 'deleted',
      text: 'Delete'
    }
    ]

    get permissions () {
      return AuthModule.permissions
    }

    protected job: any = {
      id: null,
      status: null,
      commenced_date: null,
      is_agent_letter: false,
      is_import_export_license: false,
      is_sri_lanka_customs: false,
      is_harbor_master: false,
      is_slpa_security: false,
      is_sri_lanka_navy: false,
      is_slpa_trader_form: false,
      remark: null,
      modified_user: null
    }

    created () {
      this.boot()
    }

    protected boot () {
      SettingService.getJobDetails(JobModule.job.id).then((response) => {
        this.job = {
          id: response.data.id,
          commenced_date: response.data.commenced_date,
          is_agent_letter: response.data.is_agent_letter === 1,
          is_import_export_license: response.data.is_import_export_license === 1,
          is_sri_lanka_customs: response.data.is_sri_lanka_customs === 1,
          is_harbor_master: response.data.is_harbor_master === 1,
          is_slpa_security: response.data.is_slpa_security === 1,
          is_sri_lanka_navy: response.data.is_sri_lanka_navy === 1,
          is_slpa_trader_form: response.data.is_slpa_trader_form === 1,
          remark: response.data.remark
        }

        if (response.data.status === 'commenced' || response.data.status === 'attend' || response.data.status ===
        'loaded' || response.data.status === 'dispatch' || response.data.status === 'received' || response.data
          .status === 'completed') {
          this.job.status = 'commenced'
        } else this.job.status = response.data.status

        if (this.job.remark !== null) this.isRemark = true
        else this.isRemark = false
      }).catch(error => { console.log(error) })
    }

    protected save () {
      if (this.job.commenced_date === null) this.job.commenced_date = DateHelper.today('dd/mm/yyyy')
      this.job.modified_user = AuthModule.user.fullname

      SettingService.update(this.job).then(response => {
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected approve () {
      this.job.modified_user = AuthModule.user.fullname
      SettingService.approve(this.job).then((response) => {
        ToastModule.message(response.data.message)
      }).catch(error => { ToastModule.message(error.response.data.message) })
    }
}

